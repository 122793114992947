<template>
  <el-card class="mint-token-card" :body-style="{ padding: '10px' }">
    <el-image
      :src="tokenData.image"
      class="image"
      fit="scale-down"
      style="width: 100%; height: auto"
    />
  </el-card>
</template>
<script>
  export default {
    name: 'PalabrasMintTokenCard',
    props: ['tokenData'],
  }
</script>
<style scoped>
  .token-name {
    font-size: 0.7em;
    white-space: nowrap;
  }
  .mint-token-card {
    max-width: 98%;
  }
</style>

<template>
  <div v-if="mintingStep > 0">
    <canvas class="confetti-canvas" ref="confettiCanvasRef"></canvas>
    <div
      style="width: 25vw; min-width: 300px; margin: 50px auto 0 auto"
      id="carousel-div"
      v-if="mintingStep > 0"
    >
      <div v-if="mintingStep == 1">
        <div class="title-center">MINTING IN PROGRESS</div>
        <img
          src="../../assets/s1-loading2.gif"
          style="width: 100px; margin: 2% 0"
        />
        <div class="step-message">
          Processing
          <a :href="txUrl" style="color: #409eff !important" target="_blank"
            >transaction</a
          >...
        </div>
      </div>
      <div v-if="mintingStep == 2">
        <div class="title-center">MINTING SUCCESSFUL</div>
        <img
          src="../../assets/s1-loading2.gif"
          style="width: 100px; margin: 2% 0"
        />
        <div class="step-message">
          {{
            `Please wait while your ${
              mintedQty > 1 ? 'tokens are' : 'token is'
            } being generated...`
          }}
        </div>
        <div class="step-message">
          <small
            >Feel free to refresh to go back to the minting page. Your tokens
            will continue to be generated in the background.</small
          >
        </div>
      </div>
      <div v-if="mintingStep == 3">
        <div class="title-center">CONGRATULATIONS!</div>
        <div class="step-message">
          {{
            `Here ${mintedQty > 1 ? 'are' : 'is'} your  ${
              mintedQty > 1 ? 'tokens' : 'token'
            }`
          }}
        </div>
        <div style="margin: 20px auto 0 auto">
          <el-carousel
            :interval="2000"
            indicator-position="none"
            arrow="never"
            autoplay="false"
            :style="{ height: carouselHeight + 'px' }"
          >
            <el-carousel-item v-for="tokenData in tokensData" :key="tokenData">
              <el-card
                :body-style="{
                  margin: '0',
                  height: carouselHeight - 42 + 'px',
                }"
              >
                <iframe
                  :src="tokenData.animation_url"
                  frameborder="0"
                  width="100%"
                  height="100%"
                ></iframe>
              </el-card>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="margin-top: 20px">
          <el-button type="primary" class="mint-exp-btn" @click="postTweet"
            >Share on Twitter</el-button
          >
          <el-button type="success" class="mint-exp-btn" @click="mintAgain"
            >Mint another</el-button
          >
        </div>
      </div>
      <div v-if="mintingStep == 4">
        <div class="title-center">TRANSACTION FAILED</div>
        <div class="step-message">
          {{ txError }}
        </div>
        <div style="margin-top: 30px">
          <el-button type="success" @click="mintAgain">Go back</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import confetti from 'canvas-confetti'
  import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
  import { useStore } from 'vuex'
  import { getTokensByTxHash } from '@/services/TokenService'

  export default {
    name: 'MintingExperience',
    props: ['projectSlug'],
    setup(props) {
      const store = useStore()
      const {
        dispatch,
        state: { mintingExperience, root },
      } = store
      const confettiCanvasRef = ref(null)
      const mintingStep = computed(() => mintingExperience.mintingStep)
      const txHash = computed(() => mintingExperience.txHash)
      const mintedQty = computed(() => mintingExperience.mintedQty)
      const txError = computed(() => mintingExperience.txError)
      const selectedProject = computed(() => root.selectedProject)
      const aspectRatio = ref(0)

      const txUrl = ref('')
      const tokensData = ref([])
      let isCheckingToken = false
      let intervalID

      const carouselHeight = ref(0)

      const calculateCarouselHeight = () => {
        const carouselDiv = document.getElementById('carousel-div')
        if (!carouselDiv) return
        const containerWidth =
          document.getElementById('carousel-div').clientWidth
        carouselHeight.value = containerWidth * (1 / aspectRatio.value)
      }

      onMounted(() => {
        aspectRatio.value = selectedProject.value.configuration.aspectRatio
        calculateCarouselHeight()
        window.addEventListener('resize', calculateCarouselHeight)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', calculateCarouselHeight)
      })

      const mintAgain = () => {
        window.location.reload()
      }

      const postTweet = () => {
        const text = `I just minted Studio Uno ${
          props.projectSlug.charAt(0).toUpperCase() + props.projectSlug.slice(1)
        } #${tokensData.value[0].tokenID} \n\n https://studiouno.io/tokens/${
          props.projectSlug
        }/${
          tokensData.value[0].tokenID
        } \n\n Mint yours at https://studiouno.io/${props.projectSlug}`
        window
          .open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`,
            '_blank'
          )
          .focus()
      }

      watch([txHash], () => {
        txUrl.value =
          process.env.NODE_ENV === 'production'
            ? `https://etherscan.io/tx/${txHash.value}`
            : `https://goerli.etherscan.io/tx/${txHash.value}`
      })

      watch([mintingStep], async () => {
        if (mintingStep.value == 2) {
          if (!isCheckingToken) {
            intervalID = setInterval(async function async() {
              if (
                tokensData.value.length >= mintedQty.value &&
                tokensData.value.every((t) => {
                  return t.animation_url
                })
              ) {
                setTimeout(() => {
                  dispatch('setMintingStep', 3)
                  calculateCarouselHeight()
                }, 5000)
              } else {
                tokensData.value = (
                  await getTokensByTxHash(props.projectSlug, txHash.value)
                ).response
              }
            }, 5000)
          }
        } else if (mintingStep.value == 3) {
          clearInterval(intervalID)
          var myConfetti = confetti.create(confettiCanvasRef.value, {
            resize: true,
            useWorker: true,
          })
          var end = Date.now() + 120 * 1000

          var colors = ['#bb0000', '#409eff', '#ffffff']

          ;(function frame() {
            myConfetti({
              particleCount: 2,
              angle: 60,
              spread: 55,
              origin: { x: 0 },
              colors: colors,
            })
            myConfetti({
              particleCount: 2,
              angle: 120,
              spread: 55,
              origin: { x: 1 },
              colors: colors,
            })

            if (Date.now() < end) {
              requestAnimationFrame(frame)
            }
          })()
        }
      })

      return {
        mintingStep,
        confettiCanvasRef,
        txUrl,
        txError,
        tokensData,
        mintedQty,
        carouselHeight,
        postTweet,
        mintAgain,
      }
    },
  }
</script>

<style scoped>
  .title-center {
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
  }
  .confetti-canvas {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .step-message {
    margin: 2% 0;
    color: #409eff !important;
    font-size: 14px;
    font-weight: bolder;
    text-decoration: none;
    padding: 0 10%;
  }
  .mint-exp-btn {
    margin: 10px;
    min-width: 180px;
  }
</style>
<style>
  .el-carousel__container {
    height: 100% !important;
  }
</style>
